<template>
  <div
    class="form-wrapper"
    style="border-radius: 0 0 5px 5px"
  >
    <div class="mb-1">
      <select-account
        :auto-suggest-table-columns="autoSuggestTableColumnsAccount"
        :module-name-orders="MODULE_NAME_ORDERS"
        :module-name="MODULE_NAME"
        :customer-i-d="customerID"
        label="Supplier Name"
        @selectHandler="selectHandler"
        @onSearchEmpty="onSearchEmpty"
      />
    </div>
    <b-row>
      <b-col md="6">
        <component
          :is="serviceOrderInformationFields['billing_contact_id'].type"
          ref="billing_contact_id_ref"
          v-model="serviceOrderInformationForm['billing_contact_id']"
          class="h-auto"
          v-bind="getProps('billing_contact_id', serviceOrderInformationFields, true, 'billing_contact_id', getParentValue)"
          @input="onChangeBillingContact($event,serviceOrderInformationForm)"
        />
        <component
          :is="serviceOrderInformationFields[field].type"
          v-for="field in ['billing_email', 'billing_phone', 'billing_address', 'internal_account_notes']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="serviceOrderInformationForm[field]"
          class="h-auto"
          :is-editable="true"
          v-bind="getProps(field, serviceOrderInformationFields)"
        />
        <component
          :is="serviceOrderInformationFields['payment_term_id'].type"
          ref="payment_term_id_ref"
          v-model="serviceOrderInformationForm['payment_term_id']"
          class="h-auto"
          v-bind="getProps('payment_term_id', serviceOrderInformationFields)"
        />
      </b-col>
      <b-col md="6">
        <upload-attachment />
        <component
          :is="serviceOrderInformationFields[field].type"
          v-for="field in ['internal_order_reference', 'internal_notes']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="serviceOrderInformationForm[field]"
          class="h-auto"
          v-bind="getProps(field, serviceOrderInformationFields)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import SelectAccount from '@/views/main/orders/components/use-as-global/SelectAccount.vue'
import { getProps, getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import config from '../config'
import UploadAttachment from '../../../common/UploadAttachment.vue'

export default {
  name: 'ServiceOrderInformation',
  components: {
    BRow,
    BCol,
    SelectAccount,
    UploadAttachment,
  },
  data() {
    return {
      hasAddress: true,
    }
  },
  computed: {
    serviceOrderInformationForm() {
      return this.$store.state[this.MODULE_NAME].serviceOrderInformationForm
    },
    serviceOrderInformationFormDefaultValue() {
      return this.$store.state[this.MODULE_NAME].serviceOrderInformationFormDefaultValue
    },
    customerID() {
      return this.$store.state[this.MODULE_NAME].order.customer_id
    },
    customer() {
      return this.$store.state[this.MODULE_NAME].accountParametersForm
    },
  },
  methods: {
    getParentValue() {
      return this.serviceOrderInformationForm.customer_id
    },
    onSearchEmpty() {
      this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_ORDER_INFORMATION_FORM`, {
        // ...this.serviceOrderInformationFormDefaultValue,
        attachments: [],
        isEmpty: true,
      })
    },

    onChangeBillingContact(contact, data) {
      const { email, phone_one } = contact
      // eslint-disable-next-line camelcase
      // const billingPhone = JSON.parse(cell_no_object)?.formatInternational
      this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_ORDER_INFORMATION_FORM`, {
        ...this.serviceOrderInformationForm,
        billing_email: email,
        billing_phone: phone_one,
      })
    },
    returnFiledLabel(key) {
      return this.getCustomer?.[key]?.map(item => item?.label)
    },
    selectHandler(item) {
      const {
        id, customer_detail, default_address, default_contact,
      } = item
      const street = default_address?.street ?? ''
      const line2 = default_address?.line2 ?? ''
      const city = default_address?.city ?? ''
      const state = default_address?.state?.name ?? ''
      const zip = default_address?.zip ?? ''
      const country = default_address?.country?.name ?? ''

      const paymentTerm = customer_detail?.payment_term ?? null

      this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_ORDER_INFORMATION_FORM`, {
        ...this.serviceOrderInformationForm,
        customer_id: id,
        payment_term_id: paymentTerm,
        billing_address: `${street}, ${line2}, ${city}, ${state}, ${zip}, ${country}`,
        internal_account_notes: customer_detail?.comments ?? '',
        billing_email: default_contact?.email,
        billing_phone: default_contact?.phone_one,

      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
      serviceType,
      MODULE_NAME_ORDERS,
      MODULE_NAME_ATTACHMENT,
      serviceOrderInformationFields,
      autoSuggestTableColumnsAccount,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      serviceType,
      MODULE_NAME_ORDERS,
      MODULE_NAME_ATTACHMENT,
      serviceOrderInformationFields,
      autoSuggestTableColumnsAccount,
    }
  },
}
</script>
<style lang="scss">
.service_order_information_service_type {
  & > div {
    display: flex;
    width: 100%;
  }

  & .custom-radio {
    width: 50%;
    padding: 0 !important;

  }

  & .custom-control-label {
    width: 100%;
    display: flex;
    gap: 3%;
    &::before{
      bottom: 0;
      position: relative !important;
      left: 1% !important;
    }
  }
}
</style>
